<!--
 * @Description: 
 * @Autor: wangwangwang
 * @Date: 2024-01-27 19:10:40
 * @LastEditors: wangwangwang
 * @LastEditTime: 2024-02-27 19:40:58
-->
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        mounted() {
            let myHeaders = new Headers();
            myHeaders.append("token", "");
            myHeaders.append("User-Agent", "Apifox/1.0.0 (https://apifox.com)");
            myHeaders.append("Accept", "*/*");
            myHeaders.append("Host", "47.108.203.140:3000");
            myHeaders.append("Connection", "keep-alive");
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                }),
                redirect: 'follow'
            };
            fetch("/api/news/list", requestOptions)
            .then(response => response.text())
            .then(result => {
                if (result) {
                    let res = JSON.parse(result);
                    let allNewsList = res?.data.data;
                    window.sessionStorage.setItem("allNewsList", JSON.stringify(allNewsList));
                }
            })
            
            fetch("/api/banner/list", requestOptions)
            .then(response => response.text())
            .then(result => {
                if (result) {
                    let res = JSON.parse(result);
                    let allBannerList = res?.data.data;
                    window.sessionStorage.setItem("allBannerList", JSON.stringify(allBannerList));
                }
            })
        }
    }
</script>

<style lang="scss"></style>
